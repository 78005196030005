<template>
  <div v-if="!loading">
    <b-row class="flex-column">
      <b-col>
        <h2 class="font-weight-bold text-dark">
          {{ $t('quote') }}
          #{{ quote.folio }}
        </h2>
        <span class="font-weight-bolder">
          {{ $t('updatedPrices') }} {{ new Date().getDate() }}/{{
            new Date().getMonth() + 1
          }}/{{ new Date().getFullYear() }}
        </span>
        <p class="text-muted">
          {{ $t('imageInfo') }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-card no-body>
          <quotes-table
            :quotes="[{ ...quote }]"
            :fields="quoteFields"
            no-tools
          >
            <template #cell(address)="data">
              {{ data.value.city }}
            </template>
          </quotes-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <!-- <b-col>
        <client-pricing-list
          :quote="quote"
          :products="quote.quote_items_attributes"
        />
      </b-col> -->
    </b-row>
    <b-row class="">
      <b-col sm="12">
        <b-card
          class="overflow-hidden"
          no-body
        >
          <b-table
            :no-border-collapse="true"
            responsive
            :items="metadataItems"
            :fields="metadataFields"
            class="mb-0"
          >
            <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
            <template #head(supplier)="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
            <template #cell(unit_price)="data">
              <div>${{ data.value | money }}</div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col>
        <b-card
          class="overflow-hidden"
          no-body
        >
          <b-table
            :no-border-collapse="true"
            responsive
            :items="quote.supplier_branches_shippings"
            :fields="shippingsFields"
            class="mb-0"
          >
            <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
            <template #head(supplier)="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
            <template #cell(range)="data">
              <div
                v-for="(ship, i) in data.item.shippings"
                :key="i"
              >
                <div :class="{ 'text-primary': ship.selected }">
                  <span v-if="ship.selected">{{ $t('selected') }}:</span>
                  <div>$ {{ ship.min }} - ${{ ship.max }}</div>
                  <div>{{ $t('price') }}: ${{ ship.price }}</div>
                </div>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <client-pricing-list
          :quote="quote"
          :products="quote.quote_items_attributes"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClientPricingList from '@/components/pricing/ClientPricingList.vue'
import QuotesTable from '@/components/pricing/QuotesTable.vue'

export default {
  components: {
    ClientPricingList,
    QuotesTable,
  },

  data() {
    return {
      loading: false,
      quoteFields: [
        {
          key: 'folio',
          label: this.$t('folio'),
        },
        {
          key: 'is_invoiced',
          label: this.$t('isInvoiced'),
        },
        {
          key: 'is_payed',
          label: this.$t('isPayed'),
        },
        {
          key: 'is_published',
          label: this.$t('isPublished'),
        },
        {
          key: 'is_shipped',
          label: this.$t('isShipped'),
        },
        {
          key: 'quote_status',
          label: this.$t('quoteStatus'),
        },
      ],
      shippingsFields: [
        {
          key: 'name',
          label: this.$t('name'),
        },
        {
          key: 'range',
          label: this.$t('shippings'),
        },
      ],
      metadataItems: [],
      metadataFields: [],
      chartdata: {
        labels: ['Prov 1', 'Prov2', 'Prov 3', 'Prov 4'],
        datasets: [
          {
            label: this.$t('prices'),
            fill: false,
            borderColor: '#bb81fc',
            pointBackgroundColor: '#bb81fc',
            data: [10, 13, 8, 5],
            tension: 0,
            pointRadius: 3,
            pointBorderWidth: 9,
            spanGaps: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        // scales: {
        //   yAxes: {
        //     ticks: {
        //       stepSize: 5,
        //     },
        //   },
        // },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
                stepSize: 0,
              },
            },
          ],
        },
      },
    }
  },

  computed: {
    ...mapGetters('products', {
      categories: 'productsGroupedByCategory',
      products: 'products',
    }),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('app', ['currentBreakPoint', 'isSmallScreen']),
  },
  created() {
    this.loading = true
    this.fetchQuoteMetadata(this.$route.params.id).then(response => {
      // get rid of null values by converting them to empty objects
      for (
        let i = 0;
        i < response.supplier_branches_comparative.length;
        i += 1
      ) {
        response.supplier_branches_comparative[
          i
        ] = response.supplier_branches_comparative[i].map(item => {
          if (item) return item
          return { unit_price: '-' }
        })
      }
      const quote = { ...response }
      const branchPrices = quote.supplier_branches_comparative
      const fields = branchPrices.slice(1).map(row => ({
        key: row[0].id,
        label: row[0].name,
        tdClass: 'text-center',
        thClass: 'text-center align-middle text-wrap',
        thStyle: { width: '20%' },
      }))
      fields.unshift({
        key: 'supplier',
        label: this.$t('supplier'),
        stickyColumn: true,
        isRowHeader: true,
        thStyle: { width: '20%' },
        thClass: 'align-middle',
      })
      const branchPricesT = this.transpose(quote.supplier_branches_comparative)
      // const items = branchPricesT.slice(1).map((row, index)=>({supplier: row[0].name, supplierId: row[0].id, fields[index+1].id:row }))
      const items = []
      for (let i = 1; i < branchPricesT.length; i += 1) {
        const item = {
          supplier: branchPricesT[i][0].name,
          supplierId: branchPricesT[i][0].id,
        }
        for (let j = 1; j < branchPricesT[i].length; j += 1) {
          if (branchPricesT[i][j].unit_price > -1) {
            item[fields[j].key] = `$ ${this.$options.filters.money(
              branchPricesT[i][j].unit_price,
            )} MXN`
          } else {
            item[fields[j].key] = branchPricesT[i][j].unit_price
          }
        }
        items.push(item)
      }
      this.metadataFields = fields
      this.metadataItems = items
      this.loading = false
    })
  },
  methods: {
    ...mapActions('quotes', ['fetchQuoteMetadata']),

    transpose(mx) {
      const h = mx[0].length
      const w = mx.length
      // const matrix = [...mx]
      // const matrixT = Array(w).fill([])
      const t = []
      for (let i = 0; i < h; i += 1) {
        // Insert a new row (array)
        t[i] = []

        // Loop through every item per item in outer array (width)
        for (let j = 0; j < w; j += 1) {
          // Save transposed data.
          t[i][j] = mx[j][i]
        }
      }
      return t
    },
  },
}
</script>

<style></style>
